// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut, updatePassword, sendEmailVerification } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";



// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBYDhGWjMfG5Vo8qoW4tnUl4ASIkJeicDg",
    authDomain: "hosting-and-maintenance.firebaseapp.com",
    projectId: "hosting-and-maintenance",
    storageBucket: "hosting-and-maintenance.appspot.com",
    messagingSenderId: "595184962435",
    appId: "1:595184962435:web:74784a2422557f894244b6",
    measurementId: "G-WDB7BM4HTK"
  };


const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize auth with the app instance
const dbFirestore = getFirestore(app); // Initialize dbFirestore with the app instance
const storage = getStorage(app);


export { auth, dbFirestore, storage, onAuthStateChanged, signOut, updatePassword, sendEmailVerification }; // Export auth and dbFirestore directly
export default app;